<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import simplebar from "simplebar-vue";
import API from "@/router/layouts/API";
import CopyableText from "@/components/CopyableText";

export default {
  page: {
    title: "Data API",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    simplebar,
    API,
    CopyableText
  },
  data() {
    return {

    };
  },
  validations: {

  },
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      this.$nextTick(() => {
        //document.scrollingElement.scrollTop -= 1;
      });
    },
  }
};
</script>

<template>
  <API>
    <!-- LINKS -->
    <template v-slot:contents>
      <ul class="c-menu">
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('introduction')"
          >
            Introduction
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('serverid')"
          >
            Server Id
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('authentication')"
          >
            Authentication
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('grant')"
          >
            Grant process and access
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('gameserver')"
          >
            Game-Server
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('server')"
          >
            Server
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('banlist')"
          >
            Banlist
          </a>
        </li>
        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('users')"
          >
            Users
          </a>
        </li>

        <li>
          <a
              class="font-size-16"
              href="javascript:void(0);"
              v-on:click="goto('errors')"
          >
            Error codes
          </a>
        </li>
      </ul>
    </template >
    <!-- CONTENT -->
    <template v-slot:content>
      <!-- Introduction -->
      <div class="c-api-section" ref="introduction">
        <h1 class="pb-0">
          Introduction
        </h1>
        <hr>
        <div class="c-api-content">
          CFTools Cloud provides a public API interface for everyone to use at <a href="https://data.cftools.cloud">https://data.cftools.cloud</a>.
          The page is only served via HTTPS and acts as root url for all API methods.
        </div>
      </div>
      <!-- Server Id -->
      <div class="c-api-section" ref="serverid">
        <h1 class="pb-0">
          Server Id
        </h1>
        <hr>
        <div class="c-api-content">
          CFTools Cloud's Steamrelay service automatically queries all game servers for specific games in a 30 to 60 second interval.
          To retrieve information about game servers you need to generate a Data API specific server resource id, referenced to as server_id in the following.
          <br>
          <br>
          The server_id generates from following datasets:
          <ul>
            <li>IPv4 of the game server</li>
            <li>Gameport of the game server</li>
            <li>Game identifier</li>
          </ul>

          <table class="table table-bordered">
            <thead>
              <th>Game</th>
              <th>Identifier</th>
            </thead>
            <tbody>
              <tr>
                <td>DayZ</td>
                <td>
                  <span class="text-code text-info">
                    1
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          Once you have gathered this information, build a string with these parameters in the following order:
          <span class="text-code text-info">{game_identifier}{ipv4}{game_port}</span>
          <br>
          Do not use any spacers or other additional symbols.
          <br>
          <br>
          After you have this string use a sha1 hashing function on it. The digest in hexademical form can then be used as server_id.
          <br>
          <br>
          Example with ipv4 = 127.0.0.1 , game_port = 2302, game_identifier = 1:
          <ul>
            <li>Build string: <span class="text-code text-info">1127.0.0.12302</span></li>
            <li>sha1 hash string <span class="text-code text-info">sha1(1127.0.0.12302)</span></li>
            <li>Use hex-digest of hashed string as server_id: <span class="text-code text-info">c0d1db47b89ade22740fd3b2a5e79393dfc56c90</span></li>
          </ul>
        </div>
      </div>

      <!-- Authentication -->
      <div class="c-api-section" ref="authentication">
        <h1 class="pb-0">
          Authentication
        </h1>
        <hr>
        <div class="c-api-content">
          Some routes require authentication in the form of Bearer tokens in the request headers.
          <br>
          <br>
          To log in send a <b class="text-code text-warning">POST</b> request with your application_id and secret to <span class="text-code text-warning">/v1/auth/register</span>.
          You will receive a token that is valid for 24 hours.
          <br>
          <br>
          Add this token to your Authorization request header in the format of <span class="text-code text-success">Bearer {token}</span>
          <br>
          <br>
          Upon expiration you receive the error message expired-token and are forced to reauthenticate as described above.
          <div class="c-api-spacer"/>
          <h3>Register</h3>
          <h3>
            <span class="text-code text-warning">[POST]</span>
            <CopyableText class="text-code" text="/v1/auth/register" />
            <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>2/minute</span>
          </h3>
          Log in and claim an authorization token
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="application_id"/>
              </td>
              <td>
                Your application id
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="secret"/>
              </td>
              <td>
                Your application secret
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Grant -->
      <div class="c-api-section" ref="grant">
        <h1 class="pb-0">
          Grant process and access permissions
        </h1>
        <hr>
        <div class="c-api-content">
          When trying to access restricted routes or data, an API application must be granted access by the resource owner.
          <br>
          This permission can be granted by sending the resource owner the <b>"Grant URL"</b> you can find on your application dashboard.
          <br>
          <div class="c-api-spacer"/>
          <h3>Get Grants</h3>
          <h3>
            <span class="text-code text-warning">[GET]</span>
            <CopyableText class="text-code" text="/v1/@app/grants" />
            <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>1/minute</span>
          </h3>
          Get list of all grants and their respective id's.
        </div>
      </div>

      <!-- Server -->
      <div class="c-api-section" ref="gameserver">
        <h1 class="pb-0">
          Game-Server
        </h1>
        <hr>
        <div class="c-api-content">
          <div class="alert alert-info">
            This references to <b>GAME SERVER ENTITIES</b>. This is not the scope which allows you to retrieve information about CFCloud instances.
          </div>
          All subsequent routes require a <span class="text-code text-success">Server Id</span>, which can be generated as documented at
          <a class="font-size-16" href="javascript:void(0);" v-on:click="goto('serverid')">Server Id</a>.

          <div class="c-api-spacer"/>
          <h3>Get server details</h3>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/gameserver/{server_id}" /></h3>
          Get server details by <span class="text-code text-success">Server Id</span>.
        </div>
      </div>

      <div class="c-api-section" ref="server">
        <h1 class="pb-0">
          Server
        </h1>
        <hr>
        <div class="c-api-content">
          All subsequent routes require a <span class="text-code text-success">Server API Id</span> and an active application grant.

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/info" /></h3>
          Get general information about the registered server

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/statistics" /></h3>
          Get server statistics

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/GSM/list" /></h3>
          Get full player list

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/kick" /></h3>
          Kick a player
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="gamesession_id"/>
              </td>
              <td>
                An active gamesession id (See <CopyableText class="text-code" text="/v1/server/{server_api_id}/GSM/list" /> for details)
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="reason"/>
              </td>
              <td>
                Reason <span class="badge badge-info">length: 1-128</span>
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/message-private" /></h3>
          Send a private message to a player
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="gamesession_id"/>
              </td>
              <td>
                An active gamesession id (See <CopyableText class="text-code" text="/v1/server/{server_api_id}/GSM/list" /> for details)
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="content"/>
              </td>
              <td>
                Message content <span class="badge badge-info">length: 1-256</span>
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/message-server" /></h3>
          Send a public message to the server
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="content"/>
              </td>
              <td>
                Message content <span class="badge badge-info">length: 1-256</span>
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/raw" /></h3>
          Send a raw RCon command to the server
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="command"/>
              </td>
              <td>
                Command <span class="badge badge-info">length: 1-256</span>
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

	        <div class="c-api-spacer"/>
	        <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/GameLabs/actions" /></h3>
	        Retrieve list of all available dynamic actions for designated game server. <span class="badge badge-info">GameLabs required</span> <span class="badge badge-info ml-4">Not all games supported</span>
	        <table class="table table-bordered">
		        <thead>
		        <th>Parameter</th>
		        <th>Value</th>
		        <th>Status</th>
		        </thead>
		        <tbody>

		        </tbody>
	        </table>
	        <div class="badge badge-info text-black">
		        Returns 200 on success
	        </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/GameLabs/action" /></h3>
          Issue a GameLabs Poll2 style dynamic action remotely <span class="badge badge-info">GameLabs required</span> <span class="badge badge-info ml-4">Not all games supported</span>
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="actionCode"/>
              </td>
              <td>
                Action code as defined in local action
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="actionContext"/>
              </td>
              <td>
                <CopyableText class="text-code" text="world"/> or <CopyableText class="text-code" text="player"/> or <CopyableText class="text-code" text="vehicle"/> or <CopyableText class="text-code" text="object"/>
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="referenceKey"/>
              </td>
              <td>
                Reference key for entity. Either SteamID 64 or GameLabs key for vehicle, event or none for world context actions
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="parameters"/>
              </td>
              <td>
                Parameters populated as defined in local action
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

	        <div class="c-api-spacer"/>
	        <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/GameLabs/batch-actions" /></h3>
	        Issue a GameLabs Poll2 style dynamic action remotely in batches <span class="badge badge-info">GameLabs required</span> <span class="badge badge-info ml-4">Not all games supported</span>
	        <table class="table table-bordered">
		        <thead>
		        <th>Parameter</th>
		        <th>Value</th>
		        <th>Status</th>
		        </thead>
		        <tbody>
		        <tr>
			        <td>
				        <CopyableText class="text-code text-success" text="actions"/>
			        </td>
			        <td>
				        List of actions. Minimum: 1, Maximum: 10;
				        <table class="table table-bordered">
					        <thead>
					        <th>Parameter</th>
					        <th>Value</th>
					        <th>Status</th>
					        </thead>
					        <tbody>
					        <tr>
						        <td>
							        <CopyableText class="text-code text-success" text="actionCode"/>
						        </td>
						        <td>
							        Action code as defined in local action
						        </td>
						        <td>
							        Required
						        </td>
					        </tr>
					        <tr>
						        <td>
							        <CopyableText class="text-code text-success" text="actionContext"/>
						        </td>
						        <td>
							        <CopyableText class="text-code" text="world"/> or <CopyableText class="text-code" text="player"/> or <CopyableText class="text-code" text="vehicle"/> or <CopyableText class="text-code" text="object"/>
						        </td>
						        <td>
							        Required
						        </td>
					        </tr>
					        <tr>
						        <td>
							        <CopyableText class="text-code text-success" text="referenceKey"/>
						        </td>
						        <td>
							        Reference key for entity. Either SteamID 64 or GameLabs key for vehicle, event or none for world context actions
						        </td>
						        <td>
							        Required
						        </td>
					        </tr>
					        <tr>
						        <td>
							        <CopyableText class="text-code text-success" text="parameters"/>
						        </td>
						        <td>
							        Parameters populated as defined in local action
						        </td>
						        <td>
							        Required
						        </td>
					        </tr>
					        </tbody>
				        </table>
			        </td>
			        <td>
				        Required
			        </td>
		        </tr>
		        </tbody>
	        </table>
	        <div class="badge badge-info text-black">
		        Returns 204 on success
	        </div>

          <div class="c-api-spacer"/>

          <!-- QUEUE PRIORITY -->

          <div class="c-api-spacer"/>
          <h3>Queue Priority</h3>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/queuepriority" /></h3>
          Get a list of all queue priority entries <span class="badge badge-info">Streamed response</span>
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                CFTools ID
              </td>
              <td>
                Optional Query Parameter
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="comment"/>
              </td>
              <td>
                String
              </td>
              <td>
                Optional Query Parameter
              </td>
            </tr>
            </tbody>
          </table>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/queuepriority" /></h3>
          Create a new queue priority entry
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                A CFTools account id
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="expires_at"/>
                <span class="badge badge-info text-black">ISO8601</span>
              </td>
              <td>
                Expiration datetime object or null; Null is a permanent entry
              </td>
              <td>
                Optional, defaults to null
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="comment"/>
              </td>
              <td>
                A note or comment
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[DELETE]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/queuepriority" /></h3>
          Delete an existing queue priority entry
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                A CFTools account id
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <!-- WHITELIST -->
          <div class="c-api-spacer"/>
          <h3>Whitelist</h3>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/whitelist" /></h3>
          Get a list of all whitelist entries <span class="badge badge-info">Streamed response</span>
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                CFTools ID
              </td>
              <td>
                Optional Query Parameter
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="comment"/>
              </td>
              <td>
                String
              </td>
              <td>
                Optional Query Parameter
              </td>
            </tr>
            </tbody>
          </table>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/whitelist" /></h3>
          Create a new whitelist entry
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                A CFTools account id
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="expires_at"/>
                <span class="badge badge-info text-black">ISO8601</span>
              </td>
              <td>
                Expiration datetime object or null; Null is a permanent entry
              </td>
              <td>
                Optional, defaults to null
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="comment"/>
              </td>
              <td>
                A note or comment
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[DELETE]</span> <CopyableText class="text-code" text="/v1/server/{server_api_id}/whitelist" /></h3>
          Delete an existing whitelist entry
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                A CFTools account id
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <!-- LEADERBOARD -->
          <div class="c-api-spacer"/>
          <h3>Leaderboard</h3>
          <h3>
            <span class="text-code text-warning">[GET]</span>
            <CopyableText class="text-code" text="/v1/server/{server_api_id}/leaderboard" />
            <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>7/minute</span>
          </h3>
          Request the generation of a leaderboard based on internally kept player stats. This may fail if no stats are present.
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="stat"/>
              </td>
              <td>
                One of
                <CopyableText class="text-code text-info" text="kills"/>
                <CopyableText class="text-code text-info" text="deaths"/>
                <CopyableText class="text-code text-info" text="suicides"/>
                <CopyableText class="text-code text-info" text="playtime"/>
                <CopyableText class="text-code text-info" text="longest_kill"/>
                <CopyableText class="text-code text-info" text="longest_shot"/>
                <CopyableText class="text-code text-info" text="kdratio"/>
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="order"/>
              </td>
              <td>
                <CopyableText class="text-code text-info" text="1"/> (Ascending) or <CopyableText class="text-code text-info" text="-1"/> (Descending)
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="limit"/>
              </td>
              <td>
                An integer between 1 and 100; Defaults to 10
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>

          <!-- PLAYER -->

          <div class="c-api-spacer"/>
          <h3>Player</h3>
          <h3>
            <span class="text-code text-warning">[GET]</span>
            <CopyableText class="text-code" text="/v2/server/{server_api_id}/player" />
            <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>120/minute</span>
          </h3>
          Individual stats of a player for a server.
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="cftools_id"/>
              </td>
              <td>
                A CFTools account id
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>


	        <h3>
		        <span class="text-code text-warning">[DELETE]</span>
		        <CopyableText class="text-code" text="/v2/server/{server_api_id}/player" />
		        <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>60/minute</span>
	        </h3>
	        Reset player statistics for individual player
	        <table class="table table-bordered">
		        <thead>
		        <th>Parameter</th>
		        <th>Value</th>
		        <th>Status</th>
		        </thead>
		        <tbody>
		        <tr>
			        <td>
				        <CopyableText class="text-code text-success" text="cftools_id"/>
			        </td>
			        <td>
				        A CFTools account id
			        </td>
			        <td>
				        Required
			        </td>
		        </tr>
		        </tbody>
	        </table>

        </div>
      </div>

      <div class="c-api-section" ref="banlist">
        <h1 class="pb-0">
          Banlist
        </h1>
        <hr>
        <div class="c-api-content">
          All subsequent routes require a <span class="text-code text-success">Banlist Id</span> and an active application grant.

          <!-- BANS -->

          <div class="c-api-spacer"/>
          <h3>Bans</h3>
          <h3><span class="text-code text-warning">[GET]</span> <CopyableText class="text-code" text="/v1/banlist/{banlist_id}/bans" /></h3>
          Get a list of all bans. <span class="badge badge-info">Streamed response</span>
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="filter"/>
              </td>
              <td>
                Either an IPv4 or a CFTools account id
              </td>
              <td>
                Optional
              </td>
            </tr>
            </tbody>
          </table>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[POST]</span> <CopyableText class="text-code" text="/v1/banlist/{banlist_id}/bans" /></h3>
          Issue a new ban. <span class="badge badge-info">Triggers an in-game kick</span>
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="format"/>
              </td>
              <td>
                <CopyableText class="text-code text-info" text="cftools_id"/> or <CopyableText class="text-code text-info" text="ipv4"/>
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="identifier"/>
              </td>
              <td>
                A CFTools account id or an IPv4; IPv4 may contain wildcard substitutes in form of an asteriks
              </td>
              <td>
                Required
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="expires_at"/>
                <span class="badge badge-info text-black">ISO8601</span>
              </td>
              <td>
                Expiration datetime object or null; Null is a permanent entry
              </td>
              <td>
                Optional, defaults to null
              </td>
            </tr>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="reason"/>
              </td>
              <td>
                A ban reason
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

          <div class="c-api-spacer"/>
          <h3><span class="text-code text-warning">[DELETE]</span> <CopyableText class="text-code" text="/v1/banlist/{banlist_id}/bans" /></h3>
          Revoke an existing ban
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="ban_id"/>
              </td>
              <td>
                The ban id of an existing ban
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
          <div class="badge badge-info text-black">
            Returns 204 on success
          </div>

        </div>
      </div>

      <div class="c-api-section" ref="users">
        <h1 class="pb-0">
          Users
        </h1>
        <hr>
        <div class="c-api-content">
          <div class="c-api-spacer"/>
          <h3>Lookup</h3>
          <h3>
            <span class="text-code text-warning">[GET]</span>
            <CopyableText class="text-code" text="/v1/users/lookup" />
            <span class="badge badge-info text-black"><i class="fad fa-tachometer-alt"/>20/minute</span>
          </h3>
          Search CFTools Cloud database for a user
          <table class="table table-bordered">
            <thead>
            <th>Parameter</th>
            <th>Value</th>
            <th>Status</th>
            </thead>
            <tbody>
            <tr>
              <td>
                <CopyableText class="text-code text-success" text="identifier"/>
              </td>
              <td>
                Either a Steam64, BattlEye GUID or Bohemia Interactive UID
              </td>
              <td>
                Required
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="c-api-section" ref="errors">
        <h1 class="pb-0">
          Error codes
        </h1>
        <hr>
        <div class="c-api-content">
          <table class="table table-bordered">
            <thead>
            <th>Status code</th>
            <th>Error text</th>
            <th>Meaning</th>
            </thead>
            <tbody>
              <!-- 400 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-method</span>
                </td>
                <td>
                  The HTTP method is not supported by this route.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">parameter-required</span>
                </td>
                <td>
                  A required parameter was not supplied. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">failed-type-validation</span>
                </td>
                <td>
                  A supplied argument failed the type validation. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-option</span>
                </td>
                <td>
                  The supplied option is not available for the selected route. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">max-length-exceeded</span>
                </td>
                <td>
                  The supplied argument exceeded the allowed length for this parameter. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">min-length-too-small</span>
                </td>
                <td>
                  The supplied argument undercut the required length for this parameter. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">length-missmatch</span>
                </td>
                <td>
                  The supplied argument did not match the required length of the parameter. Check response for details.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    400
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">duplicate</span>
                </td>
                <td>
                  The attempted creation of an entity failed as the same or a similar entity already exists.
                </td>
              </tr>

              <!-- 401 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    401
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">login-required</span>
                </td>
                <td>
                  This route requires you to supply an authentication token.
                </td>
              </tr>

              <!-- 403 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">token-regeneration-required</span>
                </td>
                <td>
                  The supplied token has been invalidated and must be regenerated.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">bad-secret</span>
                </td>
                <td>
                  The supplied secret does not match the current application secret.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">bad-token</span>
                </td>
                <td>
                  A token parameter does not match the request identity.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">expired-token</span>
                </td>
                <td>
                  The supplied token exhausted its 24 hour lifetime.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-api-key</span>
                </td>
                <td>
                  The supplied API key is invalid.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    403
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">no-grant</span>
                </td>
                <td>
                  You were not granted access to request this resource. See grant flow.
                </td>
              </tr>

              <!-- 404 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    404
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">not-found</span>
                </td>
                <td>
                  The requested route did not match any known routes.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    404
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-resource</span>
                </td>
                <td>
                  The requested resource by the supplied id could not be found.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    404
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-bucket</span>
                </td>
                <td>
                  The resource has been found, but the action could not be executed as the respective bucket was not been found or was not configured.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    404
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">invalid-bucket</span>
                </td>
                <td>
                  The resource has been found, but the action could not be executed as the respective bucket was not been found or was not configured.
                </td>
              </tr>
              <!-- 429 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    429
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">rate-limited</span>
                </td>
                <td>
                  You have exceeded the allowed rate limit for this route.
                </td>
              </tr>
              <!-- 500 -->
              <tr>
                <td>
                  <span class="text-code text-danger">
                    500
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">unexpected-error</span>
                </td>
                <td>
                  An internal error occurred. Should this error persist, contact CFTools Cloud support with the supplied <span class="text-code text-success">request_id</span>.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    500
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">timeout</span>
                </td>
                <td>
                  The requested action timed out and must be retried.
                </td>
              </tr>
              <tr>
                <td>
                  <span class="text-code text-danger">
                    500
                  </span>
                </td>
                <td>
                  <span class="text-code text-dark">system-unavailable</span>
                </td>
                <td>
                  The requested service could not be reached. You must re-attempt your request.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </API>
</template>