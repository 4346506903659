<style>
  .c-api-docs {
    overflow-x: hidden;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .c-menu {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .c-menu li {
    display: block;
    width: 100%;
  }

  .c-menu li a {
    display: block;
    padding: 0.625rem 1.5rem;
    position: relative;
    font-size: 13px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }

  .c-api-row {

  }
  .c-api-box-contents {
    margin: 0;
    padding: 0;
    width: 300px;
    background-color: #2a3042;
    position: fixed;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #262b3c;
  }
  .c-api-box-content {
    margin-left: 300px;
    padding: 1px 16px;
    height: 100%;
  }
  @media screen and (max-width: 700px) {
    .c-api-box-contents {
      width: 100%;
      height: auto;
      position: relative;
    }
    .c-api-box-content {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 400px) {
    .c-api-box-contents {
      text-align: center;
      float: none;
    }
  }

  /* Internal styling */
  .c-api-section {
    margin-bottom: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .c-api-section h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section h2 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section h4 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section h5 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section h6 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .c-api-section hr {
    border-top: 1px solid white;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .c-api-section hr .small {
    border-top: 0.5px solid white;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .c-api-content {
    margin-top: 0.5rem;
    font-size: 15px;
    color: white;
  }
  .c-api-spacer {
    margin-top: 1rem;
  }
</style>
<script>
import { layoutComputed } from "@/state/helpers";
import LayoutLoose from "@/router/layouts/LayoutLoose";

export default {
  components: { LayoutLoose },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {}
};
</script>

<template>
  <LayoutLoose>
    <div class="loose-page-content c-api-docs">
      <div class="c-api-row">
        <div class="c-api-box-contents shadow-lg">
          <div class="card hard-border">
            <div class="card-body p-2">
              <slot name="contents"/>
            </div>
          </div>
        </div>
        <div class="c-api-box-content" ref="content">
          <slot name="content"/>
        </div>
      </div>
    </div>
  </LayoutLoose>
</template>
